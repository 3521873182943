import FoPill from "../../shared/fo_pill.jsx";

export default function StreamInputDescIdleFailover({ input }) {
  const failoverSources = input.failover_sources.map(src => {
    const prio = <FoPill a={src.priority} b={src.full_name||"UNKNOWN"} />;

    var ac = <></>;

    if (src.temp_disable) {
      ac = <FoPill a="status:" b="off" />
    } else if (!src.startable) {
      ac = <FoPill a="status:" b="unstartable" intClassName="text-red-500" />
    } else {
      ac = <FoPill a="AC" b={src.availability_criterium_name} />
    }
    return <div className="flex">{prio}{ac}</div>;
  })

  return (<div className="flex flex-col">
    {failoverSources}
    <FoPill a="LR" b={input.last_resort_internal_bus_output_full_name||"UNKNOWN"} />
  </div>);
}
