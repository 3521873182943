export default function StreamTranscode({ stream }) {
  if (!stream.transcode || !stream.profile) return <></>;

  return <div className="flex my-px border-blue-100 border rounded-lg group hover:border-blue-200">
    <div className="px-2 bg-slate-200 rounded-l-lg w-28 flex-none">Transcode:</div>
    <div className="flex flex-wrap m-px items-center basis-auto lg:basis-5/12 xl:basis-8/12">
      <div className="m-px px-2 border-gray-200 border rounded-md">
        {stream.profile.video_codec_desc}</div>
      <div className="m-px px-2 border-gray-200 border rounded-md">
        {stream.profile.preset_desc}</div>
      <div className="m-px px-2 border-gray-200 border rounded-md">
        {stream.profile.profile_desc}</div>
      <div className="m-px px-2 border-gray-200 border rounded-md">
        {stream.profile.bitrate_desc}</div>
      <div className="m-px px-2 border-gray-200 border rounded-md">
        {stream.profile.scale_desc}</div>
      <div className="m-px px-2 border-gray-200 border rounded-md">
        {stream.profile.gpu_desc}</div>
    </div>
  </div>;
}

