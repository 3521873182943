
function DvbAdapterStatusSdtService({ sdtService }) {
  if (sdtService.have_fed_input) {
    return <li><b>{sdtService.desc} → {sdtService.fed_input_name}</b></li>;
  } else {
    return <li>{sdtService.desc}</li>;
  }
}

function DvbAdapterStatus({ dvbAdapter }) {
  if (!dvbAdapter.enabled) return <></>;
  if (!dvbAdapter.status.lock) {
    return <div className="flex mb-3">
      <b>NO LOCK</b>
      <b>&nbsp;&nbsp;UPTIME&nbsp;</b> 
      {dvbAdapter.status.process_uptime_s}
    </div>;
  }

  const listSdtServices = dvbAdapter.status.sdt_services.map(sdtService =>
    <DvbAdapterStatusSdtService sdtService={sdtService} />);

  const signalTitle = `${dvbAdapter.status.signal}/65535`;
  const snrTitle = `${dvbAdapter.status.snr}/65535`;

  return <>
    <div className="flex mb-3">
      <b>SIGNAL&nbsp;</b>
      <span title={signalTitle}>{dvbAdapter.status.signal_percent}</span>
      <b>&nbsp;&nbsp;SNR&nbsp;</b>
      <span title={snrTitle}>{dvbAdapter.status.snr_percent}</span>
      <b>&nbsp;&nbsp;BER&nbsp;</b>
      <span> {dvbAdapter.status.ber}</span>
      <b>&nbsp;&nbsp;UPTIME&nbsp;</b>
      <span> {dvbAdapter.status.process_uptime_s}</span>
      <b>&nbsp;&nbsp;STREAMS&nbsp;</b>
      <span> {dvbAdapter.nfed_inputs}</span>
    </div>
    <div className="flex mb-3">
      <ul>{listSdtServices}</ul>
    </div>
  </>;
}

function DvbAdapterName({ dvbAdapter }) {
  if (dvbAdapter.enabled) {
    return <>
      <b>{dvbAdapter.name}</b>
      <span>&nbsp; / &nbsp;</span>
    </>;
  } else {
    return <></>;
  }
}

function DvbAdapterDesc({ dvbAdapter }) {
  if (dvbAdapter.enabled) {
    return <b>{dvbAdapter.dsys_mod_description} {dvbAdapter.transponder_description}</b>;
  } else {
    return <b>DISABLED</b>;
  }
}

export default function DvbAdapterRoot({ dvbAdapter }) {
  const editUrl = `/dvb_adapters/${dvbAdapter.id}/edit`;
  return <div><div className="min-w-full border-2 p-2 mb-2 center">
    <div className="flex justify-between mb-3">
      <div className="flex">
        <DvbAdapterName dvbAdapter={dvbAdapter} />
        {dvbAdapter.host_adapter_name} ({dvbAdapter.host_adapter_path})
        <span>&nbsp;</span>
        <DvbAdapterDesc dvbAdapter={dvbAdapter} />
      </div>
      <div className="flex">
        <a href={editUrl} className="py-1 px-2 mx-1 bg-blue-500 hover:bg-blue-700 rounded text-xs">Edit</a>
      </div>
    </div>
    <DvbAdapterStatus dvbAdapter={dvbAdapter} />
  </div></div>;
}
