export default function StreamOutputDescInternalBus({ output }) {
  const listAttachedInputs =
    output.attached_inputs_names.map(name => <li>→ {name}</li>);
  const listAttachedFailoverInputs =
    output.attached_failover_inputs_names.map(name => <li>F→ {name}</li>);
  const listAttachedFailoverLastResortInputs =
    output.attached_failover_last_resort_inputs_names.map(name => <li>LRF→ {name}</li>);


  return (<div className="flex-col"><ul>
    {listAttachedInputs}
    {listAttachedFailoverInputs}
    {listAttachedFailoverLastResortInputs}
  </ul></div>);
}
