function streamIdDesc(srt_stream_id) {
  if (srt_stream_id) {
    return `stream_id ${srt_stream_id}`;
  } else {
    return `(null stream_id)`;
  }
}

export default function StreamInputDescSrtPull({ input }) {
  return <>SRT {input.host}:{input.port} {streamIdDesc(input.srt_stream_id)}</>;
}
