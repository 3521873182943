import { useState, useContext } from 'preact/hooks';

import { StreamContextStatusChannel } from './../../context/status_channel.jsx';
import { StreamContextStreamId } from './../../context/stream_id.jsx';

import ButtonForm from './../../shared/button_form.jsx';

// Painstakingly copied from rails templates as it is without much thinking or DRY. 
// Will optimize and cleanup later (if ever).

const blueClassName="mx-1 text-xs bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
const greenClassName="mx-1 text-xs bg-transparent hover:bg-green-500 text-blue-700 hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
const redClassName="mx-1 text-xs bg-transparent hover:bg-red-500 text-blue-700 hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"

function confirmSure(e) {
  if (!confirm("Are you sure?")) e.preventDefault();
}


function StartOutputButton({ output, onStartedUpdate, requestedStarted }) {
  const streamsStatus = useContext(StreamContextStatusChannel);
  const streamId = useContext(StreamContextStreamId);

  const url = `/streams/${streamId}/outputs/${output.id}?output[started]=true`;
  const method = 'PATCH';

  const startOutput = function(e) {
    e.preventDefault();
    streamsStatus.perform('set_output_started', 
                          { stream_id: streamId, id: output.id, started: true });
    onStartedUpdate(true);
  }

  const symbol = (requestedStarted == output.started) ? "►" : "⌚";

  const className = blueClassName + " w-8";

  return <ButtonForm method={method} url={url} text={symbol} className={className}
    onSubmit={startOutput} />
}

function StopOutputButton({ output, onStartedUpdate, requestedStarted }) {
  const streamsStatus = useContext(StreamContextStatusChannel);
  const streamId = useContext(StreamContextStreamId);

  const url = `/streams/${streamId}/outputs/${output.id}?output[started]=false`;
  const method = 'PATCH';

  const stopOutput = function(e) {
    e.preventDefault();
    streamsStatus.perform('set_output_started',
                          { stream_id: streamId, id: output.id, started: false });
    onStartedUpdate(false);
  }

  const symbol = (requestedStarted == output.started) ? "■" : "⌚";

  const className = blueClassName + " w-8";

  return <ButtonForm method="patch" url={url} text={symbol} className={className}
    onSubmit={stopOutput} />
}

function EditOutputButton({ output }) {
  const streamId = useContext(StreamContextStreamId);
  const url = `/streams/${streamId}/outputs/${output.id}/edit`;
  return <ButtonForm method="get" url={url} text="Edit" className={blueClassName} />;
}

function DeleteOutputButton({ output }) {
  const streamId = useContext(StreamContextStreamId);
  const url = `/streams/${streamId}/outputs/${output.id}`;
  return <ButtonForm method="delete" url={url} text="Delete" className={redClassName}
    onSubmit={confirmSure} />;
}

function StopOrStartOutputButtons({ output }) {
  if (!output.startable) return <></>;
  const [ requestedStarted, setRequestedStarted ] = useState(output.started);

  const onStartedUpdate = function(newRequestedState) {
    setRequestedStarted(newRequestedState)
  }

  if (output.started) {
    return <StopOutputButton output={output} requestedStarted={requestedStarted} 
                  onStartedUpdate={onStartedUpdate}/>
  } else {
    return <StartOutputButton output={output} requestedStarted={requestedStarted}
                  onStartedUpdate={onStartedUpdate} />
  }
}


export default function StreamOutputSharedButtons({ output }) {
  return (<>
    <StopOrStartOutputButtons output={output} />
    <EditOutputButton output={output} />
    <DeleteOutputButton output={output} />
  </>);
}

