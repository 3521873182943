import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['criteriumKind', 'criteriumKindGoodInputs', 'criteriumKindBadInputs',
                    'goodAvgRateKind', 'goodAvgRateHighest', 'goodAvgRateLowest',
                    'goodConsiderCcErrors', 'goodCcErrorsHighest',
                    'goodConsiderInvalidPkts', 'goodInvalidPktsHighest',
                    'goodConsiderScrambledPkts', 'goodScrambledPktsHighest',
                    'badAvgRateKind', 'badAvgRateHighest', 'badAvgRateLowest',
                    'badConsiderCcErrors', 'badCcErrorsHighest',
                    'badConsiderInvalidPkts', 'badInvalidPktsHighest',
                    'badConsiderScrambledPkts', 'badScrambledPktsHighest' ];

  connect() {
    this.changeVisibility();
  }

  update() {
    this.changeVisibility();
  }

  changeVisibility() {

    this.setVisibility(this.goodAvgRateHighestTarget,
      this.goodAvgRateKindTarget.value == 'highest' || this.goodAvgRateKindTarget.value == 'both');
    this.setVisibility(this.goodAvgRateLowestTarget,
      this.goodAvgRateKindTarget.value == 'lowest' || this.goodAvgRateKindTarget.value == 'both');

    this.setVisibility(this.badAvgRateHighestTarget,
      this.badAvgRateKindTarget.value == 'highest' || this.badAvgRateKindTarget.value == 'both');
    this.setVisibility(this.badAvgRateLowestTarget,
      this.badAvgRateKindTarget.value == 'lowest' || this.badAvgRateKindTarget.value == 'both');

    this.setVisibility(this.goodCcErrorsHighestTarget, this.goodConsiderCcErrorsTarget.checked);
    this.setVisibility(this.goodInvalidPktsHighestTarget, this.goodConsiderInvalidPktsTarget.checked);
    this.setVisibility(this.goodScrambledPktsHighestTarget, this.goodConsiderScrambledPktsTarget.checked);

    this.setVisibility(this.badCcErrorsHighestTarget, this.badConsiderCcErrorsTarget.checked);
    this.setVisibility(this.badInvalidPktsHighestTarget, this.badConsiderInvalidPktsTarget.checked);
    this.setVisibility(this.badScrambledPktsHighestTarget, this.badConsiderScrambledPktsTarget.checked);


    this.setVisibility(this.criteriumKindGoodInputsTarget,
      this.criteriumKindTarget.value == 'good_only' || this.criteriumKindTarget.value == 'both');
    this.setVisibility(this.criteriumKindBadInputsTarget,
      this.criteriumKindTarget.value == 'bad_only' || this.criteriumKindTarget.value == 'both');

    this.element.style.display = 'block';
  }

  setVisibility(t, enabled) {
    t.style.display = enabled ? 'block' : 'none';
    var nodes = t.getElementsByTagName("*");
    for (var i=0; i<nodes.length; i++) {
      nodes[i].disabled = !enabled;
    }
  }
}
