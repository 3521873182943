import FoPill from "./shared/fo_pill.jsx";

function StreamTsdecryptStatus({ stream }) {
  if (!stream.tsdecrypt || !stream.tsdecrypt_status || !stream.startable || !stream.started) return <></>;
  const status = stream.tsdecrypt_status;

  var statusSpan = <span>{status.msg_id}</span>;
  if (!status.works) {
    statusSpan = <span className="text-red-500" title={status.text}>{status.msg_id}</span>;
  }

  return <div className="flex m-px">
    <FoPill a="uptime:" intClassName="w-36">{status.process_uptime_s}</FoPill>
    <FoPill a="status:" intClassName="w-auto">{statusSpan}</FoPill>
  </div>;
}

function StreamTsdecryptCasInfo({ stream }) {
  if (stream.tsdecrypt_ca == 'CONSTCW') {
    return <FoPill a="cw:" b={stream.tsdecrypt_const_cw} />
  } else if (stream.tsdecrypt_ca == 'BISS') {
    return <FoPill a="cw:" b={stream.tsdecrypt_biss_key} />
  } else {
    return <>
        <FoPill a="host:" b={stream.tsdecrypt_hostport} />
        <FoPill a="login:" b={stream.tsdecrypt_login} />
      </>;
  }
}

export default function StreamTsdecrypt({ stream }) {
  if (!stream.tsdecrypt) return <></>;

  return <div className="flex my-px border-blue-100 border rounded-lg group hover:border-blue-200">
    <div className="px-2 bg-slate-200 rounded-l-lg w-28 flex-none">Decryption:</div>
    <div className="flex flex-wrap w-full items-center">
      <div className="flex flex-wrap m-px basis-auto lg:basis-1/2 xl:basis-5/12">
        <FoPill a="cas" b={stream.tsdecrypt_ca} />
        <StreamTsdecryptCasInfo stream={stream} />
      </div>
      <div className="flex flex-wrap flex-grow items-center">
        <div className="flex"><div><StreamTsdecryptStatus stream={stream} /></div></div>
      </div>
    </div>
  </div>;

}

