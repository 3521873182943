import StreamOutputStatusErrorLine from './error_line.jsx';

export default function StreamOutputStatusWriteRate({ status }) {
  if (status.write_rate === null && status.starting) {
    return <StreamOutputStatusErrorLine error_message="Starting..." />;
  }

  return (<div className="flex m-px">
    <div className="flex m-px border border-gray-200 rounded-md">
      <div className="px-2 w-18 bg-gray-100 rounded-l-md">bitrate:</div>
      <div className="px-2 w-36">{status.write_rate}k</div>
    </div>
  </div>);
}
