import { useState, useContext } from 'preact/hooks';

import { StreamContextStatusChannel } from './../../context/status_channel.jsx';
import ButtonForm from './../../shared/button_form.jsx';

// Painstakingly copied from rails templates as it is without much thinking or DRY. 
// Will optimize and cleanup later (if ever).

const blueClassName="mx-1 text-xs bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
const greenClassName="mx-1 text-xs bg-transparent hover:bg-green-500 text-blue-700 hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"
const redClassName="mx-1 text-xs bg-transparent hover:bg-red-500 text-blue-700 hover:text-white py-1 px-2 border border-blue-500 hover:border-transparent rounded"


function confirmSure(e) {
  if (!confirm("Are you sure?")) e.preventDefault();
}

function StartButton({ stream, onStartedUpdate, requestedStarted }) {
  const streamsStatus = useContext(StreamContextStatusChannel);

  const url = `/streams/${stream.id}?stream[started]=true`;
  const method = 'PATCH';

  const startStream = function(e) {
    e.preventDefault();
    streamsStatus.perform('set_started', { id: stream.id, started: true });
    onStartedUpdate(true);
  }

  const symbol = (requestedStarted == stream.started) ? "►" : "⌚";

  const className = blueClassName + " w-8";

  return <ButtonForm method={method} url={url} text={symbol} className={className}
    onSubmit={startStream} />
}

function StopButton({ stream, onStartedUpdate, requestedStarted }) {
  const streamsStatus = useContext(StreamContextStatusChannel);

  const url = `/streams/${stream.id}?stream[started]=false`;
  const method = 'PATCH';

  const stopStream = function(e) {
    e.preventDefault();
    streamsStatus.perform('set_started', { id: stream.id, started: false });
    onStartedUpdate(false);
  }

  const symbol = (requestedStarted == stream.started) ? "■" : "⌚";

  const className = blueClassName + " w-8";

  return <ButtonForm method="patch" url={url} text={symbol} className={className}
    onSubmit={stopStream} />
}

function VlcButton({ stream }) {
  const url = `/streams/${stream.id}/playlist.xspf`;
  return <ButtonForm method="get" url={url} text="VLC" className={greenClassName} />;
}

function EditButton({ stream }) {
  const url = `/streams/${stream.id}/edit`;
  return <ButtonForm method="get" url={url} text="Edit" className={blueClassName} />;
}

function NewOutputButton({ stream }) {
  const url = `/streams/${stream.id}/outputs/new`;
  return <ButtonForm method="get" url={url} text="Output" className={blueClassName} />;
}

function DeleteButton({ stream }) {
  const url = `/streams/${stream.id}`;
  return <ButtonForm method="delete" url={url} text="Delete" className={redClassName} 
    onSubmit={confirmSure} />;
}

function StopOrStartButtons({ stream }) {
  if (!stream.startable) return <></>;

  const [ requestedStarted, setRequestedStarted ] = useState(stream.started);

  const onStartedUpdate = function(newRequestedState) {
    setRequestedStarted(newRequestedState)
  }

  if (stream.started) {
    return (<>
      <StopButton stream={stream} requestedStarted={requestedStarted} 
                  onStartedUpdate={onStartedUpdate}/>
      <VlcButton stream={stream} />
    </>);
  } else {
    return <StartButton stream={stream} requestedStarted={requestedStarted}
                  onStartedUpdate={onStartedUpdate} />
  }
}


export default function StreamInputSharedButtons({ stream }) {
  return (<>
    <StopOrStartButtons stream={stream} />
    <EditButton stream={stream} />
    <NewOutputButton stream={stream} />
    <DeleteButton stream={stream} />
  </>);
}

