import { Component, Fragment, render } from 'preact';
import DvbAdapter from './dvb_adapter/root.jsx';

import { createConsumer } from "@rails/actioncable"

export default class DvbAdaptersPage extends Component {
  state = { dvbAdapters: [] };

  componentDidMount() {
    var self = this;
    this.consumer = createConsumer();
    this.consumer.subscriptions.create({ channel: 'DvbAdapterStatusChannel' }, {
      received(data) {
        if (Array.isArray(data)) {
          self.setState({ dvbAdapters: data });
        } else {
          console.log(data);
        }
      }
    });
  }

  componentWillUnmount() {
    this.consumer.disconnect();
    this.consumer = null;
  }


  render() {
    const listDvbAdapters = this.state.dvbAdapters.map(dvbAdapter =>
      <Fragment key={dvbAdapter.param}>
        <DvbAdapter dvbAdapter={dvbAdapter} />
      </Fragment>
    )
    return <>{listDvbAdapters}</>
  }
}

export function runDvbAdaptersPage(id) {
  render(<DvbAdaptersPage />, document.getElementById(id));

}

