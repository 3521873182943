import { Fragment } from 'preact';

import StreamPrograms from './programs.jsx';
import StreamTsdecrypt from './tsdecrypt.jsx';
import StreamTranscode from './transcode.jsx';
import StreamOutputBasic from './output/basic.jsx';

import StreamInputBasic from './input/basic.jsx';
import StreamInputRunningFailover from './input/running_failover.jsx';

function StreamInput({ stream }) {
  if (stream.input_type == 'failover' && stream.startable && stream.started) {
    return <StreamInputRunningFailover stream={stream} />
  } else {
    return <StreamInputBasic stream={stream} />
  }
}

function domId(stream) {
  return `stream_${stream.id}`;
}

function programsDomId(stream) {
  return `programs_stream_${stream.id}`;
}

export default function StreamRoot({ stream }) {
  const listOutputs = stream.outputs.map(output =>
    <Fragment key={output.id}>
      <StreamOutputBasic output={output} />
    </Fragment>
  )

  const dnn = stream.do_not_notify ? "🔕" : "";

  return (
    <div id={domId(stream)}>
      <div className="min-w-full border-blue-300 border-2 p-2 mb-2 center rounded-lg shadow-lg group hover:border-blue-500 shadow-xl">
        <div className="px-4 py-1 5 mb-3 -m-2 rounded-t shadow-lg bg-blue-300 group-hover:bg-blue-500 text-white"><div class="flex justify-between"><b>{stream.name}</b><b>{dnn}</b></div> </div>
        <div className="flex border-blue-100 border rounded-lg group hover:border-blue-200">
          <div className="px-2 bg-slate-200 rounded-l-lg w-28 flex-none">Input:</div>
          <StreamInput stream={stream} />
        </div>
        <div id={programsDomId(stream)}>
          <StreamPrograms stream={stream} />
        </div>
        <div>
          <StreamTsdecrypt stream={stream} />
        </div>
        <div>
          <StreamTranscode stream={stream} />
        </div>
        <div>
          {listOutputs}
        </div>
      </div>
    </div>
  )
}
