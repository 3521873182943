function ccErrorsTitle(cc) {
  return `CC errs: ${cc}`;
}

export default function StreamInputStatusActiveTimeReadRate({ status, log_state }) {
  return (
    <div className="flex m-px" title={ccErrorsTitle(status.cc_counter)}>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">bitrate:</div>
        <div className="px-2 w-36">{status.read_rate}k</div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">uptime:</div>
        <div className="px-2 w-36">{status.active_time}</div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">cc/1m:</div>
        <div className="px-2 w-18">{log_state.cc60}</div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">cc/10m:</div>
        <div className="px-2 w-18">{log_state.cc600}</div>
      </div>
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">cc/1h:</div>
        <div className="px-2 w-18">{log_state.cchour}</div>
      </div>
    </div>
  )
}
