import StreamOutputStatusErrorLine from './error_line.jsx';

export default function StreamOutputStatusConnectionsList({ status }) {
  if (status.connections.length == 0) {
    return <StreamOutputStatusErrorLine error_message="no connections" />;
  }

  const listConnections = status.connections.map(conn =>
    <div className="flex m-px">
      <div className="flex m-px px-2 border-gray-200 border rounded-md w-auto lg:w-48">
        {conn.peer}
      </div>
      <div className="flex m-px px-2 w-36 border-gray-200 border rounded-md">
        {conn.write_rate}k
      </div>
      <div className="flex m-px px-2 w-36 border-gray-200 border rounded-md">
        {conn.uptime_s}
      </div>
    </div>
  )
  return <>{listConnections}</>;
}
