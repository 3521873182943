export default function StreamOutputDescSrtPull({ output }) {
  if (output.srt_stream_id) {
    return (<>
      SRT stream_id {output.srt_stream_id}
    </>);
  } else {
    return (<>
      SRT (empty stream_id)
    </>);
  }
}
