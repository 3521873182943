import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ 'videoEncoder', 'rateControl', 'maxRateInput', 'avcPlusInputs', 'gpuOnlyInputs' ];

  connect() {
    this.changeVisibility();
  }

  update() {
    this.changeVisibility();
  }

  changeVisibility() {
    var videoEncoder = this.videoEncoderTarget.value;
    var rateControl = this.rateControlTarget.value;

    if (rateControl == 'vbr') {
      this.setVisibility(this.maxRateInputTarget, true);
    } else {
      this.setVisibility(this.maxRateInputTarget, false);
    }

    if (videoEncoder != 'mpeg2video') {
      this.setVisibility(this.avcPlusInputsTarget, true);
      this.setVisibility(this.gpuOnlyInputsTarget, true);
    } else {
      this.setVisibility(this.avcPlusInputsTarget, false);
      this.setVisibility(this.gpuOnlyInputsTarget, false);
    }
    this.element.style.display = 'block';
  }

  setVisibility(t, enabled) {
    t.style.display = enabled ? 'block' : 'none';
    var nodes = t.getElementsByTagName("*");
    for (var i=0; i<nodes.length; i++) {
      nodes[i].disabled = !enabled;
    }
  }
}
