function getToken() {
  return document.head.querySelector("meta[name=csrf-token]")?.content;
}

export default function ButtonForm({ method, url, text, className, onSubmit }) {
  if (method == 'get') {
    return (
      <a href={url} className={className}>{text}</a>
    )
  } else {
    return (
      <form method="post" action={url} onSubmit={onSubmit}>
        <input type="hidden" name="_method" value={method} autoComplete="off" />
        <button className={className} type="submit">{text}</button>
        <input type="hidden" name="authenticity_token" value={getToken()} autoComplete="off" />
      </form>
    )
  }
}
