import StreamInputSharedButtons from './shared/buttons.jsx';
import StreamInputStatusRunningFailover from './status/running_failover.jsx';

/* This component only used for started failover (see `if` in Stream root component) */

export default function StreamInputRunningFailover({ stream }) {
  return (
    <div className="flex flex-wrap w-full items-center">
      <div className="flex flex-wrap flex-grow items-center">
        <div className="flex flex-grow">
          <div>
            <StreamInputStatusRunningFailover input={stream.input} />
          </div>
        </div>
        <div className="flex my-1 mx-2 items-center">
          <StreamInputSharedButtons stream={stream} />
        </div>
      </div>
    </div>
  );
}
