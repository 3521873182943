export default function StreamInputStatusErrorLine({ error_message }) {
  return (
    <div className="flex">
      <div className="flex m-px border-gray-200 border rounded-md">
        <div className="px-2 w-18 bg-gray-100 rounded-l-md">status:</div>
        <div className="text-red-500 px-2 w-36">{error_message}</div>
      </div>
      <div className="flex w-64">
      </div>
    </div>
  )
}
