import StreamOutputSharedButtons from './shared/buttons.jsx';

import StreamOutputDescHttpPull from './desc/http_pull.jsx';
import StreamOutputDescInternalBus from './desc/internal_bus.jsx';
import StreamOutputDescSrtPull from './desc/srt_pull.jsx';
import StreamOutputDescUdpMulticast from './desc/udp_multicast.jsx';

import StreamOutputStatusConnectionsList from './status/connections_list.jsx';
import StreamOutputStatusErrorLine from './status/error_line.jsx';
import StreamOutputStatusWriteRate from './status/write_rate.jsx';

function StreamOutputDesc({ output }) {
  switch(output.type) {
    case 'httpPull':
      return <StreamOutputDescHttpPull output={output} />;
    case 'srtPull':
      return <StreamOutputDescSrtPull output={output} />;
    case 'internalBus':
      return <StreamOutputDescInternalBus output={output} />;
    case 'udpMulticast':
      return <StreamOutputDescUdpMulticast output={output} />;
    default:
      throw new Error(`Unknown output type ${stream.output_type}`);
  }
}

function StreamOutputStatus({ output }) {
  if (!output.works) {
    return <StreamOutputStatusErrorLine error_message={output.error_message} />
  }

  switch(output.type) {
    case 'httpPull':
      return <StreamOutputStatusConnectionsList status={output.status} />;
    case 'srtPull':
      return <StreamOutputStatusConnectionsList status={output.status} />;
    case 'internalBus':
      return <StreamOutputStatusWriteRate status={output.status} />;
    case 'udpMulticast':
      return <StreamOutputStatusWriteRate status={output.status} />;
    default:
      throw new Error(`Unknown output type ${stream.output_type}`);
  }
}

export default function StreamOutputBasic({ output }) {
  return (<div>
    <div className="flex border-blue-100 border rounded-lg my-px group hover:border-blue-200">
      <div className="px-2 bg-slate-200 rounded-l-lg w-28 flex-none">Output:</div>
      <div className="flex flex-wrap w-full items-center">
        <div className="flex m-px basis-auto lg:basis-1/2 xl:basis-5/12">
          <div className="flex px-2"><b>{output.name}</b></div>
          <div className="flex px-2 m-px border-gray-100">
            <StreamOutputDesc output={output} />
          </div>
        </div>

        <div className="flex flex-wrap flex-grow items-center">
          <div className="flex flex-grow"><div>
            <StreamOutputStatus output={output} />
          </div></div>
          <div className="flex my-1 mx-2 items-center">
            <StreamOutputSharedButtons output={output} />
          </div>
        </div>
      </div>
    </div>
  </div>);
}
