import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'outputType', 'httpPullOutput', 'srtPullOutput', 'udpMulticastOutput', 'hlsOutput', 'internalBusOutput' ];

  connect() {
    this.changeVisibility();
  }

  update() {
    this.changeVisibility();
  }

  changeVisibility() {
    var outputType = this.outputTypeTarget.value;
    [ this.httpPullOutputTarget, this.srtPullOutputTarget,
      this.hlsOutputTarget, this.udpMulticastOutputTarget,
      this.internalBusOutputTarget ].forEach((t) => {
        t.style.display = (t.dataset.outputFormTarget == `${outputType}Output` ? 'block' : 'none');
        var nodes = t.getElementsByTagName("*");
        for (var i=0; i<nodes.length; i++) {
          nodes[i].disabled = t.dataset.outputFormTarget != `${outputType}Output`
        }
    })

    this.element.style.display = 'block';
  }
}
