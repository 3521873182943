import FoPill from "./shared/fo_pill.jsx";

function StreamProgramStream({ stream }) {
  const desc = stream.may_be_scrambled ? `* ${stream.desc}` : `${stream.desc}`;

  return <div className="flex flex-wrap m-px">
    <FoPill a={stream.pid} b={desc} />
    <FoPill a="kind:" b={stream.kind} />
    <FoPill a="lang:" b={stream.lang} />
    <FoPill a="rate:" b={stream.rate} />
  </div>;
};

function StreamProgramStreams({ program }) {
  const streams = program.streams.map(stream => <StreamProgramStream stream={stream} />);
  return <>{streams}</>;
}

function StreamProgramEcmStream({ ecmStream }) {
  if (ecmStream.provid) {
    return <FoPill a={ecmStream.pid}>CAID {ecmStream.caid} PROV {ecmStream.provid}</FoPill>;
  } else {
    return <FoPill a={ecmStream.pid}>CAID {ecmStream.caid}</FoPill>;
  }
};

function StreamProgramEcmStreams({ program }) {
  const ecmStreams = program.ecm_streams.map(ecmStream =>
        <StreamProgramEcmStream ecmStream={ecmStream} />);

  if (ecmStreams.length > 0) {
    return <div className="flex flex-wrap m-px">{ecmStreams}</div>
  } else {
    return <></>;
  }
}

function StreamProgramFta({ program }) {
  if (program.free_ca) {
    return <FoPill a="FTA:" b="true" />
  } else {
    return <></>;
  }
};
function StreamProgram({ program }) {
  return <div className="flex my-px border-blue-100 border rounded-lg group hover:border-blue-200">
    <div className="px-2 bg-slate-200 rounded-l-lg w-28 flex-none">Program:</div>
    <div className="flex flex-col flex-wrap w-full items-left">
      <div className="flex flex-wrap m-px">
        <FoPill a="SID:" b={program.sid} />
        <FoPill a="Provider:" b={program.provider} />
        <FoPill a="Service:" b={program.service} />
        <StreamProgramFta program={program} />
      </div>
      <StreamProgramStreams program={program} />
      <StreamProgramEcmStreams program={program} />
    </div>
  </div>;
}

export default function StreamPrograms({ stream }) {
  const programs = stream.status.programs.map(program => <StreamProgram program={program} />);
  return <>{programs}</>;
}

