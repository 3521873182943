import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'mode', 'staticInputs'];

  connect() {
    this.changeVisibility();
  }

  update() {
    this.changeVisibility();
  }

  changeVisibility() {
    var mode = this.modeTarget.value;

    if (mode == 'dhcp') {
      this.setVisibility(this.staticInputsTarget, false);
    } else {
      this.setVisibility(this.staticInputsTarget, true);
    }

    this.element.style.display = 'block';
  }

  setVisibility(t, enabled) {
    t.style.display = enabled ? 'block' : 'none';
    var nodes = t.getElementsByTagName("*");
    for (var i=0; i<nodes.length; i++) {
      nodes[i].disabled = !enabled;
    }
  }
}
