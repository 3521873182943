import StreamInputSharedButtons from './shared/buttons.jsx';

import StreamInputDescDvb from './desc/dvb.jsx';
import StreamInputDescHls from './desc/hls.jsx';
import StreamInputDescHttpPull from './desc/http_pull.jsx';
import StreamInputDescIdleFailover from './desc/idle_failover.jsx';
import StreamInputDescInternalBus from './desc/internal_bus.jsx';
import StreamInputDescSrtPull from './desc/srt_pull.jsx';
import StreamInputDescUdpMulticast from './desc/udp_multicast.jsx';
import StreamInputDescUdpUnicast from './desc/udp_unicast.jsx';

import StreamInputStatusActiveTimeReadRate from './status/active_time_read_rate.jsx';
import StreamInputStatusErrorLine from './status/error_line.jsx';
/* This component only used for 'idle' (i.e. stopped/unstartable) failover,
 * (see `if` in Stream root component), so no RunningFailover here. */

function StreamInputDesc({ stream }) {
  switch(stream.input_type) {
    case 'hls':
      return <StreamInputDescHls input={stream.input} />;
    case 'httpPull':
      return <StreamInputDescHttpPull input={stream.input} />;
    case 'srtPull':
      return <StreamInputDescSrtPull input={stream.input} />;
    case 'udpMulticast':
      return <StreamInputDescUdpMulticast input={stream.input} />;
    case 'udpUnicast':
      return <StreamInputDescUdpUnicast input={stream.input} />;
    case 'dvb':
      return <StreamInputDescDvb input={stream.input} />;
    case 'internalBus':
      return <StreamInputDescInternalBus input={stream.input} />;
    case 'failover':
      return <StreamInputDescIdleFailover input={stream.input} />;
    default:
      throw new Error(`Unknown input_type ${stream.input_type}`);
  }
}

function StreamInputStatus({ stream }) {
  if (stream.works) {
    return <StreamInputStatusActiveTimeReadRate status={stream.status} log_state={stream.log_state} />
  } else {
    return <StreamInputStatusErrorLine error_message={stream.error_message} />
  }
}

export default function StreamInputBasic({ stream }) {
  return (
    <div className="flex flex-wrap w-full items-center">
      <div className="flex m-px px-2 basis-auto lg:basis-5/12 xl:basis-5/12">
        <StreamInputDesc stream={stream} />
      </div>
      <div className="flex flex-wrap flex-grow items-center">
        <div className="flex flex-grow">
          <div>
            <StreamInputStatus stream={stream} />
          </div>
        </div>
        <div className="flex my-1 mx-2 items-center">
          <StreamInputSharedButtons stream={stream} />
        </div>
      </div>
    </div>
  );
}
