export default function StreamOutputDescHttpPull({ output }) {
  if (output.url) {
    return (<p>
      HTTP/TS {output.url}
    </p>);
  } else {
    return (<p>
      HTTP/TS {output.path} @ {output.listener_id}
    </p>);
  }
}
