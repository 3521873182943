import FoPill from "../../shared/fo_pill.jsx";

function Cwnd({ cwnd }) {
  const pills = Object.keys(cwnd).map(k => <FoPill a={`${k}`} b={`${cwnd[k]}`} />);
  return <>{pills}</>;
}

export default function StreamInputStatusRunningFailover({ input }) {
  const failoverSources = input.failover_sources.map(src => {
    const prio = <FoPill a={src.priority} b={src.full_name||"UNKNOWN"}
                        extClassName={src.active ? "font-bold" : ""} />
    var ac = <></>;

    if (src.temp_disable) {
      ac = <FoPill a="status:" b="off" />
    } else if (!src.startable) {
      ac = <FoPill a="status:" b="unstartable" intClassName="text-red-500" />
    } else if (src.stat) {
      ac = <>
        <FoPill a="AC" b={src.stat.state} />
        <FoPill a="bitrate:" b={`${src.stat.input_rate}k`} intClassName="w-36" />
        <FoPill a="uptime:" b={`${src.stat.active_time}`} intClassName="w-36"/>
      </>
    } else {
      ac = <FoPill a="status:" b="internal error" intClassName="text-red-500" />
    }
    var goodCwnd = <></>;
    if (src.stat && src.stat.good_cwnd && Object.keys(src.stat.good_cwnd).length > 0) {
      goodCwnd = <div className="flex">
        &nbsp; &nbsp; &nbsp; &nbsp;
        <FoPill a="CWND G" b={`${src.availability_criterium_good_window_seconds}`} />
        <Cwnd cwnd={src.stat.good_cwnd} />
      </div>;
    }
    var badCwnd = <></>;
    if (src.stat && src.stat.bad_cwnd && Object.keys(src.stat.bad_cwnd).length > 0) {
      badCwnd = <div className="flex">
        &nbsp; &nbsp; &nbsp; &nbsp;
        <FoPill a="CWND B" b={`${src.availability_criterium_bad_window_seconds}`} />
        <Cwnd cwnd={src.stat.bad_cwnd} />
      </div>;
    }

    return (<>
      <div className="flex">{prio}{ac}</div>
      {goodCwnd}{badCwnd}
    </>);
  });

  var lrStat = <></>;
  if (input.last_resort_stat) {
    lrStat = <>
      <FoPill a="bitrate:" b={`${input.last_resort_stat.input_rate}k`} intClassName="w-36" />
      <FoPill a="uptime:" b={`${input.last_resort_stat.active_time}`} intClassName="w-36" />
    </>;
  }

  return (<div className="flex flex-col">
    {failoverSources}
    <div className="flex">
      <FoPill a="LR" b={input.last_resort_internal_bus_output_full_name||"UNKNOWN"}
        extClassName={input.last_resort_active ? "font-bold" : ""} />
      {lrStat}
    </div>
  </div>);
}
