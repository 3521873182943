import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'dsysMod', 'dvbsInputs' ];

  connect() {
    this.changeVisibility();
  }

  update() {
    this.changeVisibility();
  }

  changeVisibility() {
    var dsys_mod = this.dsysModTarget.value;
    var isDvbs = dsys_mod.startsWith('dvbs');
    this.setVisibility(this.dvbsInputsTarget, isDvbs);

    this.element.style.display = 'block';
  }

  setVisibility(t, enabled) {
    t.style.display = enabled ? 'block' : 'none';
    var nodes = t.getElementsByTagName("*");
    for (var i=0; i<nodes.length; i++) {
      nodes[i].disabled = !enabled;
    }
  }
}
